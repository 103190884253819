import { axiosInstance } from '../api/axiosConfig';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CartReview from '../components/checkoutProcess/CartReview';
import PaymentForm from '../components/checkoutProcess/PaymentForm';
import OrderConfirmation from '../components/checkoutProcess/OrderConfirmation';
import LoginOrGuestModal from '../components/userAccountDashboard/LoginOrGuestModal';
import { fetchUser } from '../features/user/userSlice';
import { clearCart } from '../features/cart/cartSlice';
import './Checkout.scss';
import '../components/checkoutProcess/CartReview.scss';
import '../components/checkoutProcess/PaymentForm.scss';
import '../components/checkoutProcess/OrderConfirmation.scss';

const stripePromise = loadStripe('pk_test_51IUG1xDeMzWWwem83ELrjXCqI49RUU7ZBKykGxBcf7BhAwzCdE9SUKeXVI9SdqKEcHbCHDFpEtue0P2zZIPNsuNI00s3oth1d6');

const Checkout = () => {
  const cart = useSelector(state => state.cart.items);
  const user = useSelector(state => state.user.userInfo);
  const token = useSelector(state => state.user.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [order, setOrder] = useState(null);
  const [email, setEmail] = useState(user ? user.email : '');
  const [shippingAddress, setShippingAddress] = useState({
    address_line1: '',
    address_line2: '',
    city: '',
    county: '',
    post_code: '',
    country: ''
  });
  const [showModal, setShowModal] = useState(false);
  const [formError, setFormError] = useState('');

  useEffect(() => {
    if (!token) {
      setShowModal(true);
    } else {
      dispatch(fetchUser()).then(action => {
        if (action.payload) {
          setEmail(action.payload.email || '');
          setShippingAddress({
            address_line1: action.payload.address_line1 || '',
            address_line2: action.payload.address_line2 || '',
            city: action.payload.city || '',
            county: action.payload.county || '',
            post_code: action.payload.post_code || '',
            country: action.payload.country || ''
          });
        } else {
          setShowModal(true);
        }
      });
    }
  }, [dispatch, token]);

  const nextStep = () => {
    if (step === 1) {
      // Validate form fields
      if (
        !email ||
        !shippingAddress.address_line1 ||
        !shippingAddress.city ||
        !shippingAddress.post_code ||
        !shippingAddress.country
      ) {
        setFormError('Please fill in all required fields.');
        return;
      } else {
        setFormError('');
      }
    }
    setStep(prevStep => prevStep + 1);
  };

  const prevStep = () => setStep(prevStep => prevStep - 1);

  const handleLogin = () => {
    setShowModal(false);
    navigate('/login');
  };

  const handleRegister = () => {
    setShowModal(false);
    navigate('/register');
  };

  const handleGuest = () => {
    setShowModal(false);
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setShippingAddress(prev => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (step === 2 && token) {
      const createOrder = async () => {
        const items = cart.map(item => ({
          product: item.id,
          quantity: item.quantity || 1,
          price: parseFloat(item.price),
          discount_price: parseFloat(item.discount_price) || parseFloat(item.price),
          total_price: parseFloat(item.price) * (item.quantity || 1),
        }));

        const total_price = items.reduce((total, item) => total + item.total_price, 0);

        const payload = {
          email: email.toString().trim(),
          shipping_address: `${shippingAddress.address_line1}, ${shippingAddress.address_line2}, ${shippingAddress.city}, ${shippingAddress.county}, ${shippingAddress.post_code}, ${shippingAddress.country}`,
          items: items,
          total_price: parseFloat(total_price).toFixed(2),
          status: 'pending',
          payment_status: 'unpaid',
        };

        try {
          const response = await axiosInstance.post('/orders/create/', payload, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          setOrder(response.data.order);
        } catch (error) {
          console.error('Error creating order:', error.response ? error.response.data : error);
        }
      };

      createOrder();
    }
  }, [cart, step, email, shippingAddress, token]);

  const handleOrderCompletion = () => {
    dispatch(clearCart());
    navigate('/');
  };

  return (
    <Elements stripe={stripePromise}>
      <div className="checkout__container">
        <h1 className="checkout__header">Checkout</h1>
        {step === 1 && (
          <div>
            <h2>Step 1: Review Your Cart</h2>
            {formError && <p className="form-error">{formError}</p>}
            <CartReview
              cart={cart}
              email={email}
              setEmail={setEmail}
              shippingAddress={shippingAddress}
              handleAddressChange={handleAddressChange}
              nextStep={nextStep}
            />
          </div>
        )}
        {step === 2 && order && (
          <div>
            <h2>Step 2: Payment Information</h2>
            <PaymentForm orderId={order.id} nextStep={nextStep} prevStep={prevStep} setOrder={setOrder} />
          </div>
        )}
        {step === 3 && order && (
          <OrderConfirmation order={order} handleOrderCompletion={handleOrderCompletion} />
        )}
        <LoginOrGuestModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          handleLogin={handleLogin}
          handleRegister={handleRegister}
          handleGuest={handleGuest}
        />
      </div>
    </Elements>
  );
};

export default Checkout;