import { axiosInstance } from '../api/axiosConfig';

export const fetchArticles = () => axiosInstance.get('/content/articles/');
export const fetchGuides = () => axiosInstance.get('/content/guides/');
export const fetchVideos = () => axiosInstance.get('/content/videos/');
export const fetchContentDetail = (id) => axiosInstance.get(`/content/content/${id}/`);
export const fetchComments = (contentId) => axiosInstance.get(`/content/comments/?content=${contentId}`);
export const fetchTags = () => axiosInstance.get('/content/tags/');
export const fetchRatings = (contentId) => axiosInstance.get(`/content/ratings/?content=${contentId}`);
export const searchContent = (query) => axiosInstance.get(`/content/search/?q=${query}`);

const contentApi = {
    fetchArticles,
    fetchGuides,
    fetchVideos,
    fetchContentDetail,
    fetchComments,
    fetchTags,
    fetchRatings,
    searchContent,
};

export default contentApi;