import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import './GuideCard.scss';

const GuideCard = ({ content }) => (
  <Card className="content-card">
    {content.cover_image && <Card.Img variant="top" src={content.cover_image} alt={content.title} />}
    <Card.Body>
      <Card.Title className="card-title">{content.title}</Card.Title>
      <Card.Text className="card-summary">{content.summary}</Card.Text>
      <Link to={`/content/guide/${content.id}`} className="btn btn-primary">
        Read more
      </Link>
    </Card.Body>
  </Card>
);

export default GuideCard;