import React, { useEffect, useState } from 'react';
import { fetchContentDetail, fetchComments, fetchRatings } from '../api/contentApi';
import { useParams } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import './ArticleDetail.scss';

const ArticleDetail = () => {
  const { id } = useParams();
  const [content, setContent] = useState(null);
  const [comments, setComments] = useState([]);
  const [ratings, setRatings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getContentDetail = async () => {
      try {
        const { data: contentData } = await fetchContentDetail(id);
        const { data: commentData } = await fetchComments(id);
        const { data: ratingData } = await fetchRatings(id);
        
        setContent(contentData);
        setComments(commentData);
        setRatings(ratingData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching content detail', error);
      }
    };
    getContentDetail();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const sanitizedContent = sanitizeHtml(content.body, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat([
      'img', 'h1', 'h2', 'h3', 'p', 'ul', 'ol', 'li', 'strong', 'em', 'a', 'table', 'tr', 'th', 'td', 'thead', 'tbody', 'iframe']),
    allowedAttributes: {
      ...sanitizeHtml.defaults.allowedAttributes,
      img: ['src', 'alt', 'width', 'height', 'style'],
      a: ['href', 'target'],
      iframe: ['src', 'width', 'height', 'frameborder', 'allow', 'allowfullscreen'],
      '*': ['style', 'class', 'id']
    },
  });

  return (
    <Container className="content-detail article-detail">
      <h1 className="title">{content.title}</h1>
      <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} className="body-content" />
      <Row className="mt-4">
        <Col md={6}>
          <Card className="comments-section">
            <Card.Body>
              <Card.Title>Comments</Card.Title>
              {comments.map(comment => (
                <Card.Text key={comment.id}>
                  {comment.text} <FontAwesomeIcon icon={faThumbsUp} /> {comment.likes}
                </Card.Text>
              ))}
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="ratings-section">
            <Card.Body>
              <Card.Title>Ratings</Card.Title>
              {ratings.map(rating => (
                <Card.Text key={rating.id}>Score: {rating.score}</Card.Text>
              ))}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ArticleDetail;