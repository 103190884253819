import React, { useState, useEffect } from 'react';
import { Container, Tabs, Tab, Row, Col, Carousel } from 'react-bootstrap';
import SearchComponent from '../components/contentHub/SearchComponent';
import ArticleCard from '../components/contentHub/ArticleCard';
import GuideCard from '../components/contentHub/GuideCard';
import VideoCard from '../components/contentHub/VideoCard';
import { fetchArticles, fetchGuides, fetchVideos } from '../api/contentApi';
import './ContentHubPage.scss';

const ContentHubPage = () => {
  const [articles, setArticles] = useState([]);
  const [guides, setGuides] = useState([]);
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getContent = async () => {
      try {
        const [articleData, guideData, videoData] = await Promise.all([
          fetchArticles(),
          fetchGuides(),
          fetchVideos(),
        ]);
        setArticles(articleData.data);
        setGuides(guideData.data);
        setVideos(videoData.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching content:', error);
        setLoading(false);
      }
    };
    getContent();
  }, []);

  const handleSearchResults = (results) => {
    const articles = results.filter(item => item.content_type === 'article');
    const guides = results.filter(item => item.content_type === 'guide');
    const videos = results.filter(item => item.content_type === 'video');
    setArticles(articles);
    setGuides(guides);
    setVideos(videos);
  };

  return (
    <Container className="content-hub-page">
      <div className="hero-section">
        <h1 className="content-hub-title">Explore Our Content Hub</h1>
        <SearchComponent onSearch={handleSearchResults} />
      </div>

      {loading ? (
        <div>Loading...</div>
      ) : (
        <Tabs defaultActiveKey="articles" id="content-tabs" className="content-tabs">
          <Tab eventKey="articles" title="Articles">
            <Carousel className="featured-carousel">
              {articles.slice(0, 3).map(content => (
                <Carousel.Item key={content.id}>
                  <ArticleCard content={content} />
                </Carousel.Item>
              ))}
            </Carousel>
            <Row className="content-list">
              {articles.map(content => (
                <Col xs={12} md={6} lg={4} key={content.id}>
                  <ArticleCard content={content} />
                </Col>
              ))}
            </Row>
          </Tab>
          <Tab eventKey="guides" title="Guides">
            <Carousel className="featured-carousel">
              {guides.slice(0, 3).map(content => (
                <Carousel.Item key={content.id}>
                  <GuideCard content={content} />
                </Carousel.Item>
              ))}
            </Carousel>
            <Row className="content-list">
              {guides.map(content => (
                <Col xs={12} md={6} lg={4} key={content.id}>
                  <GuideCard content={content} />
                </Col>
              ))}
            </Row>
          </Tab>
          <Tab eventKey="videos" title="Videos">
            <Carousel className="featured-carousel">
              {videos.slice(0, 3).map(content => (
                <Carousel.Item key={content.id}>
                  <VideoCard content={content} />
                </Carousel.Item>
              ))}
            </Carousel>
            <Row className="content-list">
              {videos.map(content => (
                <Col xs={12} md={6} lg={4} key={content.id}>
                  <VideoCard content={content} />
                </Col>
              ))}
            </Row>
          </Tab>
        </Tabs>
      )}
    </Container>
  );
};

export default ContentHubPage;