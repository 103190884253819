import { axiosInstance } from '../../api/axiosConfig';
import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
// import { useDispatch } from 'react-redux';
// import { clearCart } from '../../features/cart/cartSlice';
import './PaymentForm.scss';

const PaymentForm = ({ orderId, nextStep, prevStep, setOrder }) => {
    const stripe = useStripe();
    const elements = useElements();
    // const dispatch = useDispatch();
    const [order, setLocalOrder] = useState(null);

    useEffect(() => {
        axiosInstance.get(`/orders/orders/${orderId}/`)
            .then(response => {
                setLocalOrder(response.data);
                setOrder(response.data);  // Pass the order data to the parent component
            })
            .catch(error => console.error('Error fetching order:', error));
    }, [orderId, setOrder]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements || !order) {
            console.error('Stripe.js has not loaded yet or order is not created.');
            return;
        }
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        });
        if (!error) {
            axiosInstance.post(`/orders/${orderId}/process_payment/`, { paymentMethod: paymentMethod.id })
                .then(response => {
                    if (response.data.status === 'requires_action') {
                        stripe.confirmCardPayment(response.data.payment_intent_client_secret)
                            .then(result => {
                                if (result.error) {
                                    console.error('Payment failed:', result.error);
                                } else {
                                    nextStep();
                                }
                            });
                    } else if (response.data.status === 'Payment successful') {
                        nextStep();
                    } else {
                        console.error('Payment failed:', response.data.error);
                    }
                })
                .catch(error => console.error('Payment error:', error));
        } else {
            console.error('Stripe payment method error:', error);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="payment-form">
            <CardElement className="card-element" />
            <button type="submit" disabled={!stripe || !elements || !order} className="pay-button">Pay</button>
            <button type="button" onClick={prevStep} className="back-button">Previous Step</button>
        </form>
    );
};

export default PaymentForm;