import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../api/axiosConfig'; // Correct import

// Fetch user details
export const fetchUser = createAsyncThunk('user/fetchUser', async (_, { getState, rejectWithValue }) => {
  const state = getState();
  const token = state.user.token;

  if (!token) {
    return rejectWithValue('No token found');
  }

  try {
    const response = await axiosInstance.get('/accounts/user/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log('Fetch user response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Fetch user error:', error.response ? error.response.data : error.message);
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});

// Update user details
export const updateUser = createAsyncThunk('user/updateUser', async (userData, { getState, rejectWithValue }) => {
  const state = getState();
  const token = state.user.token;

  if (!token) {
    return rejectWithValue('No token found');
  }

  try {
    const response = await axiosInstance.put(`/accounts/users/${userData.id}/`, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Update user error:', error.response ? error.response.data : error.message);
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});

// Register user
export const registerUser = createAsyncThunk('user/registerUser', async (userData, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post('/accounts/register/', userData);
    // Save token to local storage
    localStorage.setItem('token', response.data.access);
    return response.data;
  } catch (error) {
    console.error('Register user error:', error.response ? error.response.data : error.message);
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});

// Login user
export const loginUser = createAsyncThunk('user/loginUser', async (userData, { dispatch, rejectWithValue }) => {
  try {
    const response = await axiosInstance.post('/accounts/login/', userData);
    // Store the token in local storage
    localStorage.setItem('token', response.data.access);
    // Fetch user details after successful login
    await dispatch(fetchUser());
    return response.data;
  } catch (error) {
    console.error('Login user error:', error.response ? error.response.data : error.message);
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});

// Logout user
export const logoutUser = createAsyncThunk('user/logoutUser', async (_, { dispatch }) => {
  try {
    await axiosInstance.post('/accounts/logout/');
  } catch (error) {
    console.error('Logout error:', error);
  }
  // Clear the token from local storage
  localStorage.removeItem('token');
  // Clear the user data and token from the state
  dispatch(clearUserData());
});

const userSlice = createSlice({
  name: 'user',
  initialState: {
    userInfo: null,
    status: 'idle',
    error: null,
    token: localStorage.getItem('token'), // Retrieve token from local storage
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    clearUserData: (state) => {
      state.userInfo = null;
      state.token = null;
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.userInfo = action.payload;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updateUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.userInfo = action.payload;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(registerUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.userInfo = action.payload.user;
        state.token = action.payload.access;
        state.error = null;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(loginUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.userInfo = action.payload.user;
        state.token = action.payload.access;
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const { clearError, clearUserData } = userSlice.actions;

export default userSlice.reducer;