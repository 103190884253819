import { axiosInstance } from '../api/axiosConfig';

import { addToCart } from '../features/cart/cartSlice';
import { addToFavourites } from '../features/favourites/favouritesSlice';

export const syncCartAndFavourites = async (userId, dispatch) => {
  const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
  const favouritesItems = JSON.parse(localStorage.getItem('favouritesItems')) || [];

  // Sync Cart
  try {
    const response = await axiosInstance.post('/orders/syncCart/', { userId, cartItems }, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      }
    });
    response.data.items.forEach(item => dispatch(addToCart(item)));
  } catch (error) {
    console.error('Error syncing cart:', error);
  }

  // Sync Favourites
  try {
    const response = await axiosInstance.post('/orders/syncFavourites/', { userId, favouritesItems }, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      }
    });
    if (response.data && response.data.items) {
      response.data.items.forEach(item => dispatch(addToFavourites(item)));
    } else {
      console.log('No favourites found in sync response');
    }
  } catch (error) {
    console.error('Error syncing favourites:', error);
  }
};
