import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Homepage from './pages/Homepage';
import ProductCatalogue from './pages/ProductCatalogue';
import ProductDetailPage from './pages/ProductDetailPage';
import Checkout from './pages/Checkout';
import UserDashboard from './pages/UserDashboard';
import ContentHubPage from './pages/ContentHubPage';
import ArticleDetail from './pages/ArticleDetail';
import GuideDetail from './pages/GuideDetail';
import VideoDetail from './pages/VideoDetail';
import CustomerSupport from './pages/CustomerSupport';
import RegisterComponent from './pages/RegisterComponent';
import LoginComponent from './pages/LoginComponent';
import Cart from './pages/Cart';
import Favourites from './pages/Favourites';
import MembershipPage from './pages/MembershipPage';
import ReturnForm from './components/userAccountDashboard/ReturnForm';
import ProtectedRoute from './components/ProtectedRoute';
import Layout from './components/Layout';
import './styles/global.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { syncCartAndFavourites } from './api/sync';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { fetchUser } from './features/user/userSlice';

const stripePromise = loadStripe('pk_test_51IUG1xDeMzWWwem83ELrjXCqI49RUU7ZBKykGxBcf7BhAwzCdE9SUKeXVI9SdqKEcHbCHDFpEtue0P2zZIPNsuNI00s3oth1d6');

function App() {
  const dispatch = useDispatch();
  const { userInfo, token } = useSelector(state => state.user) || {};

  useEffect(() => {
    if (token) {
      dispatch(fetchUser());
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (userInfo) {
      syncCartAndFavourites(userInfo.id, dispatch);
    }
  }, [userInfo, dispatch]);

  return (
    <Elements stripe={stripePromise}>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/catalogue" element={<ProductCatalogue />} />
            <Route path="/product/:id" element={<ProductDetailPage />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/favourites" element={<Favourites />} />
            <Route
              path="/dashboard/*"
              element={
                <ProtectedRoute>
                  <UserDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/user-dashboard/return/:orderId"
              element={
                <ProtectedRoute>
                  <ReturnForm />
                </ProtectedRoute>
              }
            />
            <Route path="/content-hub" element={<ContentHubPage />} />
            <Route path="/content/article/:id" element={<ArticleDetail />} />
            <Route path="/content/guide/:id" element={<GuideDetail />} />
            <Route path="/content/video/:id" element={<VideoDetail />} />
            <Route path="/customer-support" element={<CustomerSupport />} />
            <Route path="/register" element={<RegisterComponent />} />
            <Route path="/login" element={<LoginComponent />} />
            <Route
              path="/support-tickets"
              element={
                <ProtectedRoute>
                  <CustomerSupport />
                </ProtectedRoute>
              }
            />
            <Route
              path="/memberships"
              element={
                <ProtectedRoute>
                  <MembershipPage />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Layout>
      </Router>
    </Elements>
  );
}

export default App;