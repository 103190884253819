import axios from 'axios';
import Cookies from 'js-cookie';
import store from '../store';
import config from '../config'; // Import the config

const axiosInstance = axios.create({
  baseURL: config.apiBaseUrl, // Use dynamic base URL for your backend API
  headers: {
    'Content-Type': 'application/json',
  },
});
console.log('Using API base URL:', config.apiBaseUrl);

// Create a new Axios instance with the configured base URL
const elasticsearchInstance = axios.create({
  baseURL: config.elasticsearchBaseUrl, // Use dynamic base URL for your Elasticsearch instance
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor to include the token and CSRF token
axiosInstance.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const token = state.user.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // Include CSRF token
    const csrfToken = Cookies.get('csrftoken');
    if (csrfToken) {
      config.headers['X-CSRFToken'] = csrfToken;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { axiosInstance, elasticsearchInstance };
export default axiosInstance;